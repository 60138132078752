import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About Me`}</h1>
    <p>{`🇲🇾 Born and raised in Malaysia`}</p>
    <p>{`🇬🇧 Currently living in London`}</p>
    <p>{`👨‍🎓 Graduated from University College London`}</p>
    <p>{`📚 Studied Masters in Electronic Engineering with Computer Science`}</p>
    <p>{`🏙 Working in a FTSE 100 company`}</p>
    <p>{`🌥 Certified 4x in Amazon Web Services and 2x in Kubernetes`}</p>
    <p>{`💕 Passionate about cloud native solutions and DevOps automation`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      