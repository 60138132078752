import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="GitLab 🧪" link="https://gitlab.com/KenYew" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Come check out my GitLab profile! 💻 <br />
You can find the source code and CI/CD automation <br /> to this website! 👩‍💻
    </ProjectCard>
    <ProjectCard title="LinkedIn 🏙" link="https://www.linkedin.com/in/ken-yew-piong/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
Also check out my LinkedIn profile! 👩‍💼 <br />
Have a gander at my skills and work experience! 👀
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      